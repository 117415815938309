
import React, { Component } from 'react';
import { Navbar, Nav, FormControl, Form, Button, Row, Col, Image } from 'react-bootstrap';
import mezlogo from '../../../assets/logo/logolight.svg';


class Header extends Component {

    render() {
        const options = true;

        return (
            <div className="headerComponent">
                <Navbar bg="headerStyle" expand="lg">
                    <Navbar.Brand href="#home">
                        <Row className="logo">
                            <Col md={2} className="noPadding">
                                <Image className="logoStyle" src={mezlogo} />
                            </Col>
                            <Col md={7} className="mezLogo">
                                <p className="mezchip_logo">mezchip</p>
                            </Col>
                        </Row>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto d-lg-none">
                            <Nav.Link href="https://mezchip.com">Home</Nav.Link>
                        </Nav>

                    </Navbar.Collapse>
                </Navbar>

            </div>
        )
    }

}

export default Header;
