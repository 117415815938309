import { BASE_URL } from './constants';
import axios from "axios";
import jwt_decode from 'jwt-decode';



// TODO: add application/json inside post req.

const client = axios.create({
    baseURL: BASE_URL,
    headers: {},
});


const requestHandler = async (request) => {
    let authToken;
    const tokenExists = localStorage.getItem('authToken');
    const refreshTokenExists = localStorage.getItem('refreshToken');

    if (tokenExists && tokenExists !== "undefined") {

        authToken = JSON.parse(tokenExists);
        const accessToken = jwt_decode(authToken);
        var currentTime = Math.round(Date.now() / 1000);

        if (accessToken.exp > currentTime) {
            request.headers.Authorization = `Bearer ${authToken}`;
        } else if (refreshTokenExists && refreshTokenExists !== "undefined") {
            const refreshToken = JSON.parse(refreshTokenExists);
            const refreshTokenExp = jwt_decode(refreshToken).exp;

            if (refreshTokenExp > currentTime) {
                try {
                    const response = await axios.post(`${BASE_URL}api/refreshtoken`, {}, {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`
                        }
                    }
                    );
                    localStorage.setItem('authToken', JSON.stringify(response.data.accessToken));
                    request.headers.Authorization = `Bearer ${response.data.accessToken}`;
                }
                catch (err) {
                    console.log(`This block should catch any instantiation errors.`);
                    window.location = '/login';
                }

            }
        }

    } else { // if localstorage doesnt have access token.
        // var config = {
        //     method: 'get',
        //     url: `${BASE_URL}api/auth/token`,
        // };

        // await axios(config)
        //     .then(function (response) {
        //         console.log(response.data);
        //         const tokenResp = response.data['data'];

        //         localStorage.setItem('authToken', JSON.stringify(tokenResp.token));
        //         request.headers.Authorization = `Bearer ${tokenResp.token}`;

        //         return request;
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }
    return request;
};

const responseHandler = response => {
    if (response.status === 200) { }

    return response;
};

const errorHandler = error => {
    // console.log('error.status ---', error.response);
    if (error.response?.status === 401) {
        // TODO:
        // 1. add silent refresh token logic.
        // 2. increase token expiry time.
        window.location = '/login';
    }

    if (!error.response) {
        //for 5xx errors..
        // TODO:: create a component for 5xx errors.
        // window.location = '/login';
    }
    return Promise.reject(error);
};

client.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

client.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


export default client;