import React, { Component } from "react";
import {
    Input,
    Container,
    Row,
    Col,
    Form,
    Button,
    Alert,
    Image,
    InputGroup,
    FormControl,
} from "react-bootstrap";
import Header from "../common/header/header.component";
import client from "./../common/api";
import { withRouter } from "react-router-dom";
import creates from "../../assets/creates.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            isPasswordShown: false,
            forgotEmailAlert: {
                show: false,
                text: ''
            }
        };
    }

    componentDidMount() {
        const params = this.props.location.search;
        this.fetchEmail(params.split('?token=')[1])
    }

    togglePasswordVisiblity = () => {
        const { isPasswordShown } = this.state;
        this.setState({ isPasswordShown: !isPasswordShown });
    };

    fetchEmail = (token) => {
        const url = `api/resetpassword/${token}`;
        client.get(url).then((resp) => {
            const resetResp = resp.data;
            console.log("rest resp---", resetResp);

            if (resetResp.success) {
                this.setState({ email: resetResp.data.user });
            } else {
                this.setState({
                    forgotEmailAlert: { show: true, text: resetResp.msg }
                });
            }
        });
    }

    setEmail = (event) => {
        let email = event.target.value;
        this.setState({ email });
    };

    setPassword = (event) => {
        let password = event.target.value;
        this.setState({ password });
    };

    submitEmail = () => {
        const { password } = this.state;
        const params = this.props.location.search;
        const token = params.split('?token=')[1];
        const url = `api/resetpassword/${token}`;

        const { history } = this.props;

        if (password.length === 0) {
            this.setState({
                forgotEmailAlert: { show: true, text: 'password is required.' }
            });
        } else {
            client.post(url, { password }).then((resp) => {
                const authresp = resp.data;
                console.log("authresp---", authresp);

                if (authresp.success) {
                    const mess = `${authresp.msg}. Redirecting to login page...`;
                    this.setState({
                        forgotEmailAlert: { show: true, text: mess }
                    });
                    setTimeout(() => {
                        history.push(`/login`)
                    }, 3000);
                } else {
                    this.setState({
                        forgotEmailAlert: { show: true, text: authresp.msg }
                    });
                }
            });
        }
        setTimeout(() => {
            this.setState({
                forgotEmailAlert: { show: false, text: '' }
            })
        }, 8000);
    };

    render() {
        const { email, forgotEmailAlert, password, isPasswordShown } = this.state;

        return (
            <div className="signup_component">
                <Header />

                <Container className="">
                    <Row className="noMargin row">
                        <Col md={6} className="noPadding">
                            <h2 className="create_account loginText">
                                We respect your privacy..
                            </h2>
                            <Image className="logoStyle" src={creates} />
                        </Col>

                        <Col md={5} className="noPadding contents">

                            <Form.Group>
                                <span className="smallHeaderNote">*Email</span>
                                <Form.Control
                                    className="input_form"
                                    onChange={(event) => {
                                        this.setEmail(event);
                                    }}
                                    value={email}
                                    type="email"
                                    placeholder="johndoe@gmail.com"
                                    disabled
                                />

                                <br />
                                <span className="smallHeaderNote">*New Password</span>

                                <div className="password_section">
                                    <Form.Control
                                        required
                                        onChange={(event) => {
                                            this.setPassword(event);
                                        }}
                                        className="pass input_form"
                                        value={password}
                                        name="new password"
                                        placeholder="1234password"
                                        type={isPasswordShown ? "text" : "password"}
                                    />
                                    <FontAwesomeIcon
                                        onClick={this.togglePasswordVisiblity}
                                        className="hidden_password"
                                        icon={faEyeSlash}
                                    />
                                </div>

                            </Form.Group>

                            <Button
                                onClick={() => { this.submitEmail() }}
                                className="signup_btn"
                                variant="primary"
                            >Submit</Button>

                            {
                                forgotEmailAlert.show && (
                                    <Alert variant={`warning`}>{forgotEmailAlert.text}</Alert>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(ResetPassword);
