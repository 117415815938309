//export const BASE_URL = "https://www.staging.mezchip.com/";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const TOOLTIPS_STEPS = [
    {
        element: '.addChannel',
        title: 'Welcome',
        intro: 'create a channel',
        position: 'right',
    },
    {
        element: '#tagsCards',
        intro: 'Profile your customers by creating tags',
        position: 'right',
    },
    {
        element: '.filterOption',
        intro: 'Your open tickets appear here.',
        position: 'bottom',
    },
    {
        element: '#notifications-icon',
        intro: 'Turn notifications on to get updates when new message arrives in your Inbox',
        position: 'right',
    },
    {
        element: '.storeSection',
        intro: 'Integrate your store',
        position: 'right',
    },
];