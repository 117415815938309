import React, { Component } from "react";
import {
	Input,
	Container,
	Row,
	Col,
	Form,
	Button,
	Alert,
	Image,
	InputGroup,
	FormControl,
} from "react-bootstrap";
import Header from "../common/header/header.component";
import client from "./../common/api";
import { withRouter } from "react-router-dom";
import creates from "../../assets/creates.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			isPasswordShown: false,
			forgotEmailAlert: {
				show: false,
				text: ''
			}
		};
	}

	setEmail = (event) => {
		let email = event.target.value;
		this.setState({ email });
	};

	submitEmail = () => {
		const { email } = this.state;
		const { history } = this.props;
		const url = "api/forgetpassword";

		if (email.length === 0) {
			this.setState({
				forgotEmailAlert: { show: true, text: 'email is required.' }
			});
		} else {
			client.post(url, { email }).then((resp) => {
				const authresp = resp.data;
				if (authresp.success) {
					this.setState({
						forgotEmailAlert: { show: true, text: authresp.msg }
					});
				} else {
					this.setState({
						forgotEmailAlert: { show: true, text: authresp.msg }
					});
				}
			});
		}
		setTimeout(() => {
			this.setState({
				forgotEmailAlert: { show: false, text: '' }
			})
		}, 5000);
	};

	render() {
		const { email, forgotEmailAlert } = this.state;

		return (
			<div className="signup_component">
				<Header />

				<Container className="">
					<Row className="noMargin row">
						<Col md={6} className="noPadding">
							<h2 className="create_account loginText">
								We respect your privacy..
							</h2>
							<Image className="logoStyle" src={creates} />
						</Col>

						<Col md={5} className="noPadding contents">

							<Form.Group>
								<span className="smallHeaderNote">*Enter your Email</span>
								<Form.Control
									className="input_form"
									onChange={(event) => {
										this.setEmail(event);
									}}
									value={email}
									type="email"
									placeholder="johndoe@gmail.com"
								/>
							</Form.Group>

							<Button
								onClick={() => { this.submitEmail() }}
								className="signup_btn"
								variant="primary"
							>Submit</Button>

							{
								forgotEmailAlert.show && (
									<Alert variant={`warning`}>{forgotEmailAlert.text}</Alert>
								)
							}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(ForgotPassword);
