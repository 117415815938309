import React from 'react';

const NotFoundComponent = () => {


    return (
        <div className="notfound-div">
            <div className="mainbox">
                <div className="err">404</div>

                <div className="msg">Maybe this page moved? Got deleted? Is hiding out in quarantine?
                    Never existed in the first place?<p>Let's go <a href="https://mezchip.com">home</a> and try from there.</p></div>
            </div>
        </div>
    )
}

export default (NotFoundComponent)
