import React, { Component } from "react";
import {
	Input,
	Container,
	Row,
	Col,
	Form,
	Button,
	Alert,
	Image,
	InputGroup,
	FormControl,
} from "react-bootstrap";
import Header from "../common/header/header.component";
import client from "./../common/api";
import { withRouter } from "react-router-dom";
import creates from "../../assets/creates.svg";
import jwt from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

let email = "";

class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			oldpassword: "",
			newpassword: "",
			useremail: "",
			isoldpassword: false,
			isnewpassword: false,
			signinInfo: {
				alert: "",
				status: false,
			},
		};
	}

	toggleoldpasswordVisible = () => {
		const { isoldpassword } = this.state;
		this.setState({ isoldpassword: !isoldpassword });
	};

	togglenewpasswordVisible = () => {
		const { isnewpassword } = this.state;
		this.setState({ isnewpassword: !isnewpassword });
	}

	setoldPassword = (event) => {
		let oldpassword = event.target.value;
		this.setState({ oldpassword });
	}; //get user details and check if old password matches if yes, then update, if no then show a toaster

	setnewPassword = (event) => {
		let newpassword = event.target.value;
		this.setState({ newpassword });
	};

	componentDidMount() {
		const tokenExists = localStorage.getItem("authToken");
		if (tokenExists && tokenExists !== "undefined") {
			const authToken = jwt(JSON.parse(tokenExists));
			var logged_user_email = authToken.identity; // decode the token.
		}

		email = logged_user_email;
		console.log(email);
	}

	userchangePassword = () => {
		const { oldpassword, newpassword } = this.state;

		if (!oldpassword || !newpassword) {
			this.setState({
				signinInfo: { alert: "please fill all the fields!", status: true },
			});
		} else if (oldpassword === newpassword) {
			this.setState({
				signinInfo: { alert: "Password should not match!", status: true },
			});
		} else {
			const { history } = this.props;
			const password = oldpassword;

			const url = "/api/profile/changepassword";
			client.post(url, { email, password, newpassword }).then((resp) => {
				const authresp = resp.data;

				if (authresp.success) {
					this.setState({
						newpassword: "",
						signinInfo: { alert: "Password Successfully changed", status: true },
					});
					localStorage.setItem(
						"authToken",
						JSON.stringify(authresp.access_token)
					);
					localStorage.setItem(
						"refreshToken",
						JSON.stringify(authresp.refresh_token)
					);
				} else {
					const errorMez = authresp.msg;
					this.setState({
						signinInfo: { alert: `${errorMez}`, status: true },
					});

					setTimeout(() => {
						this.setState({
							signinInfo: {
								alert: "Process Failed Please try again!",
								status: false,
							},
						});
					}, 3000);
				}
			});
		}
	};

	render() {
		const { password, signinInfo, isoldpassword, isnewpassword } = this.state;

		return (
			<div className="signup_component">
				{/* <Header></Header> */}
				<h4 className="changePassHeader">Change Password</h4>

				<Row className="noMargin row">
					<Col className="noPadding">
						<Form.Group>
							<h5 className="profileheadertitle">*Old Password</h5>

							<div className="password_section">
								<Form.Control
									onChange={(event) => {
										this.setoldPassword(event);
									}}
									className="pass input_form"
									value={password}
									placeholder="1234password"
									type={isoldpassword ? "text" : "password"}
								/>

								<FontAwesomeIcon
									onClick={this.toggleoldpasswordVisible}
									className="hidden_password"
									icon={faEyeSlash}
								/>
							</div>

							<h5 className="profileheadertitle">*New Password</h5>

							<div className="password_section">
								<Form.Control
									onChange={(event) => {
										this.setnewPassword(event);
									}}
									className="pass input_form"
									value={password}
									placeholder="1234password"
									type={isnewpassword ? "text" : "password"}
								/>
								<FontAwesomeIcon
									onClick={this.togglenewpasswordVisible}
									className="hidden_password"
									icon={faEyeSlash}
								/>
							</div>
						</Form.Group>

						<Button
							onClick={() => {
								this.userchangePassword();
							}}
							className="signup_btn"
							variant="primary"
						>
							Change Password
						</Button>

						{signinInfo.status && (
							<Alert className="alert" variant={"info"}>
								{signinInfo.alert}
							</Alert>
						)}
					</Col>

					{/* <Col md={6} className="noPadding">
						<h2 className="create_account loginText">
							We respect your privacy..
						</h2>
						<Image className="logoStyle" src={creates} />
					</Col> */}

					{/* <Col md={5} className="noPadding contents">
						<Form.Group>
							<span className="smallHeaderNote">*Old Password</span>

							<div className="password_section">
								<Form.Control
									onChange={(event) => {
										this.setoldPassword(event);
									}}
									className="pass input_form"
									value={password}
									placeholder="1234password"
									type={isoldpassword ? "text" : "password"}
								/>

								<FontAwesomeIcon
									onClick={this.toggleoldpasswordVisible}
									className="hidden_password"
									icon={faEyeSlash}
								/>
							</div>

							<span className="smallHeaderNote">*New Password</span>

							<div className="password_section">
								<Form.Control
									onChange={(event) => {
										this.setnewPassword(event);
									}}
									className="pass input_form"
									value={password}
									placeholder="1234password"
									type={isnewpassword ? "text" : "password"}
								/>
								<FontAwesomeIcon
									onClick={this.togglenewpasswordVisible}
									className="hidden_password"
									icon={faEyeSlash}
								/>
							</div>
						</Form.Group>

						<Button
							onClick={() => {
								this.userchangePassword();
							}}
							className="signup_btn"
							variant="primary"
						>
							Change Password
						</Button>

						{signinInfo.status && (
							<Alert className="alert" variant={"info"}>
								{signinInfo.alert}
							</Alert>
						)}
					</Col> */}
				</Row>
			</div>
		);
	}
}

export default withRouter(ChangePassword);
